import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import { useFirebase } from './firebase/context'
import { Form, Input, Button, Typography, Alert } from 'antd';
import { LeftCircleOutlined } from '@ant-design/icons'

const { Title } = Typography;

const ResetPassword = () => {
    const [firebase, setFirebase] = useState(null)
    const [loading, setLoading] = useState(false)
    const [resetError, setResetError] = useState(null)

    useFirebase(firebase => {
        setFirebase(firebase)
    })

    const onFinish = (values) => {
        setLoading(true)
        const { email } = values
        firebase.auth().sendPasswordResetEmail(email)
            .then(() => {
                setLoading(false)
                navigate("/")
            })
            .catch((error) => {
                setResetError(error)
                setLoading(false)
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="aappoint-login-page">

            <div style={{ height: '100%', justifyContent: 'center', alignContent: 'center' }}>
                <div className="aappoint-login-form">
                    <Form
                        name="basic"
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item>
                            <Title level={3}>Reset Password</Title>
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[{ required: true, message: 'Please input your email!' }]}
                        >
                            <Input placeholder="Enter your email" />
                        </Form.Item>

                        <div style={{ paddingBottom: "20px", width: "100%" }}>
                            <Link to="/"><LeftCircleOutlined />&nbsp;Back</Link>
                        </div>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" block size="large" loading={loading} >
                                Reset Now
                            </Button>
                        </Form.Item>
                    </Form>
                    {
                        resetError &&
                        <Alert
                            message="Login failed"
                            description={resetError.message}
                            type="error"
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default ResetPassword