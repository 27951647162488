import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import { useFirebase } from './firebase/context'
import { Form, Input, Button, Typography, Alert } from 'antd';
import { LoginOutlined } from '@ant-design/icons';

const { Title } = Typography;

const Login = () => {
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(false)
    const [firebase, setFirebase] = useState(null)
    const [loginError, setLoginError] = useState(null)

    useFirebase(firebase => {
        setFirebase(firebase)
        firebase.auth().onAuthStateChanged(function (user) {
            setUser(user)
        });
    })

    const onFinish = (values) => {
        setLoading(true)
        const { username, password } = values
        firebase.auth().signInWithEmailAndPassword(username, password)
            .then((userCredential) => {
                // Signed in 
                var user = userCredential.user;
                setUser(user)
                setLoading(false)
                // ...
            })
            .catch((error) => {
                // var errorCode = error.code;
                // var errorMessage = error.message;
                // ..
                setLoginError(error)
                setLoading(false)
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        setLoading(false)
    };

    return (
        <div className="aappoint-login-page">
            {
                user && navigate(`/app/dashboard`)
            }

            <div style={{ height: '100%', justifyContent: 'center', alignContent: 'center' }}>
                <div className="aappoint-login-form">
                    <Form
                        name="basic"
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item>
                            <Title level={3}>Login</Title>
                        </Form.Item>
                        <Form.Item
                            label="Username"
                            name="username"
                            rules={[{ required: true, message: 'Please input your username!' }]}
                        >
                            <Input placeholder="Enter your username" />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password placeholder="Enter your password" />
                        </Form.Item>

                        <Form.Item name="remember" valuePropName="checked">
                            <div className="aappoint-center"><Link to="/?reset-password=true">Forgot password?</Link></div>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" block size="large" icon={<LoginOutlined />} loading={loading} >
                                Login
                            </Button>
                        </Form.Item>
                    </Form>
                    {
                        loginError &&
                        <Alert
                            message="Login failed"
                            description={loginError.message}
                            type="error"
                        />
                    }

                </div>
            </div>
        </div>
    )
}

export default Login