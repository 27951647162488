import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

const LaunchScreen = () => {
    return (
        <div className="aappoint-launch-screen">
            <div style={{ display: 'block' }}>
                <div>
                    <StaticImage
                        src="../images/icon.png"
                        alt="AAppoint"
                        placeholder="blurred"
                        layout="fixed"
                        width={200}
                        height={200}
                    />
                </div>
                <div style={{ width: '100%', height: '10px' }}></div>
                <div style={{ color: 'white', display: 'inline-flex', flex: '1', justifyContent: 'center', alignContent: 'center' }}>
                    <div className="aappoint-loader"></div><div>&nbsp;Loading...</div>
                </div>
            </div>
        </div>
    )
}

export default LaunchScreen;