import React, { useState } from "react"
import { navigate } from 'gatsby'
import Layout from "../components/layout"
import Login from "../components/login"
import ResetPassword from "../components/reset-password"
import LaunchScreen from '../components/launch-screen'
import { useFirebase } from '../components/firebase/context'

const Index = ({ location }) => {
    const [currentUser, setCurrentUser] = useState(null)
    const [loading, setLoading] = useState(true)
    const params = new URLSearchParams(location.search);
    const redirect = params.get("redirect")

    useFirebase(firebase => {
        firebase.auth().onAuthStateChanged(async function (user) {
            setCurrentUser(user)
            if (redirect) {
                setLoading(false)
            } else {
                setTimeout(() => {
                    setLoading(false)
                }, 500)
            }
        });
    })


    if (currentUser) {
        if (redirect && redirect !== "/") {
            navigate(redirect)
        } else {
            navigate('/app/dashboard')
        }
        return <></>
    }


    const resetPassword = params.get("reset-password") === 'true';
    return (

        (loading) ?
            <LaunchScreen /> :
            <Layout showSider={false}>
                {
                    resetPassword ? <ResetPassword /> : <Login />
                }
            </Layout>

    )
}

export default Index;